import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';


function NavMain({ menuOpen, setMenuOpen }) {

    const menuClose = () => {
        setMenuOpen(false);
    }

    const [active, setActive] = useState(null);

    return (
        <nav className={"nav-main " + (menuOpen && "active")}>
            <ul className="main">
                <li className={(active === 1 ? "active" : "")} onClick={() => {menuClose(); setActive(1) }}>
                    <NavLink to="/">Home</NavLink>
                </li>
                <li className={(active === 2 ? "active" : "")} onClick={() => {menuClose(); setActive(2) }}>
                    <NavLink to="/about">About</NavLink>
                </li>
                <li className={(active === 3 ? "active" : "")} onClick={() => {menuClose(); setActive(3) }}>
                    <NavLink to="/works">Projects</NavLink>
                </li>
                <li className={(active === 4 ? "active" : "")} onClick={() => {menuClose(); setActive(4) }}>
                    <NavLink to="/contact">Contact</NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default NavMain;