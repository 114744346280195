function Footer() {
  const getYear = () => {
    const d = new Date();
    return d.getFullYear();
  };

  return (
    <footer className='footer'>
      <p>
        Copyright &copy; {getYear()} Mudarres Amin
      </p>
    </footer>
  );
}

export default Footer;
