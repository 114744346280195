import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import profile from '../assets/profile pic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import {
  faHtml5,
  faCss3,
  faJsSquare,
  faReact,
  faSass,
  faWordpress,
  faPhp,
  faGithubSquare,
} from '@fortawesome/free-brands-svg-icons';

function PageAbout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='page-about'>
      <div className='page-about-wrapper'>
        <div className='page-about-intro'>
          <ul className='ul'>
            <Link to='/'>
              <li className='first-li'>Mudarres Amin</li>
            </Link>
            <li className='second-li'>
              <FontAwesomeIcon icon={faCaretRight} /> About{' '}
            </li>
          </ul>
          <div className='about-image'>
          <div className='about-image-wrapper'>
            <img src={profile} alt='profile' />
          </div>
        </div>
          <h2>
            Hello, I'm Mudarrees !!
          </h2>
        </div>
        <div className='page-about-main'>
          <p>
          A passionate learner diving into the exciting world of web development. I am honing my skills to create dynamic and visually appealing websites. As I navigate through the ever-evolving landscape of programming languages and design principles, I am driven by a strong curiosity and determination to master the intricacies of web development. Join me on this journey as I explore the endless possibilities of turning ideas into interactive and functional online platforms.
          </p>
          <br />
          <p>
            I am from a business backgroud. My major was in Business Administration. Transitioning from business administration to web development was my rewarding move, and many skills from your business background are highly transferable. By leveraging these transferable skills, I can effectively transition from business administration to web development and bring a unique perspective to my new role.
          </p>
        </div>
        <div className='page-about-skills'>
          <h2>Tools</h2>
          <div className='skills-wrapper'>
            <ul>
              <li>
                <FontAwesomeIcon icon={faHtml5} />
                <span>HTML</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faCss3} />
                <span>CSS</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faJsSquare} />
                <span>JavaScript</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faReact} />
                <span>React</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faSass} />
                <span>Sass</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faWordpress} />
                <span>Wordpress</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhp} />
                <span>PHP</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faGithubSquare} />
                <span>GitHub</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageAbout;
