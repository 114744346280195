import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import movieDesktop from '../assets/emily-bernal-v9vII5gV8Lw-unsplash.jpg';
import hoDesktop from '../assets/ilya-pavlov-OqtafYT5kTw-unsplash.jpg';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Works() {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <div className='works' id='works' data-aos='zoom'>
      <h2 className='works-title'>Featured Projects</h2>
      <div className='works-wrapper'>
        <article className='work-item'>
          <div className='work-item-wrapper'>
            <div className='work'>
              <div className='work-image-container'>
                <img
                  className='img1'
                  src={movieDesktop}
                  loading='lazy'
                  alt='movie website on desktop device'
                />
              </div>
            </div>
            <div className='work-info'>
              <div className='work-info-title'>
                <h3>Cine Hub</h3>
              </div>
              <p className='work-detail'>
                Cine Hub is a movie website to watch any type of movies.
              </p>
              <p className='work-tags'>Tools: React, SCSS, API</p>
              <div className='work-buttons'>
                <Link to='/works/1/cine-hub'>More Info</Link>
              </div>
            </div>
          </div>
        </article>
        <article className='work-item'>
          <div className='work-item-wrapper'>
            <div className='work'>
              <div className='work-image-container'>
                <img
                  className='img1'
                  src={hoDesktop}
                  loading='lazy'
                  alt='honey oat website on desktop device'
                />
              </div>
            </div>
            <div className='work-info'>
              <div className='work-info-title'>
                <h3>Life Care</h3>
              </div>
              <p className='work-detail'>
                Life Care is medical clinic website to get any medical services.
              </p>
              <p className='work-tags'>Tools: WordPress, PHP, SCSS</p>
              <div className='work-buttons'>
                <Link to='/works/0/life-care'>More Info</Link>
              </div>
            </div>
          </div>
        </article>
        <div className='intro-btn-container'>
            <div className="intro-text-heading">
              <h2>See my works</h2>
            </div>
            <div className="intro-btn-wrapper">
            <Link to="/works">Projects</Link>
            </div>
          </div>
      </div>
      
    </div>
   
  );
}

export default Works;
