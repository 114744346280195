import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '../components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';


function PageWorks() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <section className="page-works">
      <div className="page-works-wrapper">
        <ul className="ul">
          <Link to="/"><li className="first-li">Mudarres</li></Link>
          <li className="second-li"><FontAwesomeIcon icon={faCaretRight} /> Projects </li>
        </ul>
      </div>
      <Card />
    </section>
  );
}

export default PageWorks;