import React from 'react';
import { Link } from 'react-router-dom';
import { Projects } from './Projects';



function ProjectInfo({ id }) {


    return (
        <section className="project">
            <div className="project-wrapper">
                <div className="project-image">
                    <img src={Projects[id].headerImage} alt="a website with dvelopment" />
                </div>
                <div className="project-title">
                    <h2>{Projects[id].name}</h2>
                </div>
                <article className="first">
                    <div className="spec">
                        <h3>Project Specification:</h3>
                        <br />
                        <p>Type: <span>{Projects[id].type}</span></p>
                        <p>Duration: <span>{Projects[id].duration}</span></p>
                        <p>Tools: <span>{Projects[id].tools}</span></p>
                        <p>Role: <span>{Projects[id].involvement}</span></p>
                        <p>Responsiveness: <span>{Projects[id].responsiveness}</span></p>
                        <p>Team: <span>{Projects[id].team}</span></p>
                        <div className="links">
                            <a href={Projects[id].live}>Live Site</a>
                        </div>
                    </div>
                    <div className="overview">
                        <h3>Project Oveview:</h3>
                        <br />
                        <p>{Projects[id].overview}</p>
                    </div>
                </article>
                <div className="cta">
                    <Link to="/works"><div className="cta-button">Other Projects</div></Link>
                </div>
            </div>
        </section>
    );
}

export default ProjectInfo;