import React from 'react';
import { Link } from 'react-router-dom';

function Intro() {
  return (
    <div className='intro' id="intro">
      <div className="intro-wrapper">
        <div className="heading-wrapper">
          <h1 className='intro-text'>
            <span className="highlight">Mudarres Amin</span>
            <br />
          </h1>
          <h2 className='intro-text-two'>
            Front End Web Developer and UX/UI Designer. 
          </h2>
          <div className='intro-btn-container'>
            <div className="intro-text-heading">
              <h2>More about me...</h2>
            </div>
            <div className="intro-btn-wrapper">
            <Link to="/about">About</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;