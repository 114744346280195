import React, { useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import { LinkedIn, GitHub } from "@material-ui/icons";

function Contact() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: 'ease-in-sine'
    });
  }, []);

  return (
    <div className='contact' id="contact" data-aos="zoom">
      <div className="contact-wrapper">
        <div className="contact-title">
          <h2>Contact</h2>
        </div>
        <div className="contact-info">
          <a href="mailto:mudarres.242000@gmail.com">E-mail</a>
          <p>Thanks for visiting my profile. I'm always open to new opportunities and collaborations. You can reach me via email or connect to the social networks. Looking forward to connecting with you!</p>
        </div>
        <ul className="social">
                <li>
                    <a href="https://www.linkedin.com/in/mudarresamin/"><LinkedIn /></a>
                </li>
                <li>
                    <a href="https://github.com/"><GitHub /></a>
                </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;