import movie from '../assets/emily-bernal-v9vII5gV8Lw-unsplash.jpg';
import dicegame from '../assets/claudio-luiz-castro-_R95VMWyn7A-unsplash.jpg';
import lifecare from '../assets/ilya-pavlov-OqtafYT5kTw-unsplash.jpg';

export const Projects = [
  {
    id: 0,
    name: 'Life Care',
    slug: 'life-care',
    tags: ['WordPress', 'Javascript', 'SCSS', 'PHP'],
    main: 'WordPress',
    category: 'Static',

    headerImage: lifecare,
    type: 'Medical Services',
    duration: '4 weeks',
    team: 'Mudarres Amin, Zaif and Austin',
    involvement: 'Developer',
    tools: 'WordPress, SASS, Gulp, PHP, GitHub',
    responsiveness: 'Mobile, Tablet, Desktop',
    description:
      'HoneyOat is a sandwich store that I collaborated with 3 other developers to develop a custom theme template on WordPress WooCommerce.',

    live: 'https://mudarresamin.com/',

    overview:
      'Life Care is a medical clinic website is a modern, user-friendly platform built on WordPress with custom PHP and SCSS for enhanced functionality and styling. Designed to provide patients with easy access to vital information and services, the site features a clean, responsive design that works seamlessly across all devices. By combining the robust capabilities of WordPress with custom PHP development and SCSS styling, this medical clinic website provides a comprehensive and efficient online presence that enhances patient engagement and streamlines clinic operations.'

  },

  {
    id: 1,
    name: 'Cine Hub',
    slug: 'cine-hub',
    tags: ['React.js', 'SCSS', 'API'],
    main: 'React.js',
    category: 'Dynamic',

    headerImage: movie,
    type: 'Movie Database',
    duration: '4 weeks',
    team: 'Mudarres Amin, Priya, Simran, Dilraj',
    involvement: 'Designer and Developer',
    tools: 'React, TMDB API, SASS, GitHub, Photoshop',
    responsiveness: 'Mobile, Tablet, Desktop',

    live: 'https://mudarresamin.com/cinehub',

    overview:
      'Cine Hub is an online application for movie listings that gathers real-time information on every film currently in release using the TMDB API. Low and high fidelity prototypes were made during the planning stage, and React and SASS were used to turn them into a working website. The application has capabilities including the ability to search and display real-time results, save and remove favorite movies, and finally arrange movies into four categories: popular, highly rated, currently playing, and upcoming.',

  },

  {
    id: 2,
    name: 'Dice Game',
    slug: 'dice-game',
    tags: ['javaScript', 'HTML', 'CSS'],
    main: 'javaScript',
    category: 'Static',

    headerImage: dicegame,
    type: 'Game',
    duration: '2 weeks',
    team: 'Mudarres Amin',
    involvement: 'Designer and Developer',
    tools: 'JavaScript, HTML, CSS',
    responsiveness: 'Mobile, Tablet, Desktop',
  
    live: 'https://mudarresamin.com/dice-game',

    overview:
      'The Dice Game is a simple and interactive web-based game built using JavaScript, CSS, and HTML. The game involves two players, one of them is the computer taking turns to roll a pair of dice. Each player accumulates points based on the numbers rolled on the dice. The objective of the game is to reach a predefined score, typically 15 points, before the opponent. This dice game is a great example of how fundamental web technologies can be used to create an engaging and interactive user experience.',

  },

];
