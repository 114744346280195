import React, { useEffect } from 'react';
import Intro from '../components/Intro';
import Works from '../components/Works';

function PageHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='homepage'>
      <section className="home-sections">
        <Intro />
        <Works />
      </section>
    </section>
  );
}

export default PageHome;