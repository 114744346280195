import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from '../components/Header.jsx';
import Footer from '../components/Footer.jsx';
import PageHome from '../pages/PageHome.jsx';
import PageWorks from '../pages/PageWorks.jsx';
import PageAbout from '../pages/PageAbout.jsx';
import PageSingle from '../pages/PageSingle.jsx';
import PageContact from '../pages/PageContact.jsx';
import NavMain from '../components/NavMain.jsx';
import MobileNav from '../components/MobileNav.jsx';

function AppRouter() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState(null);

    return (
        <BrowserRouter basename='/'>
            <div className="wrapper">
                <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                <NavMain menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                <MobileNav activeLink={activeLink} setActiveLink={setActiveLink} />
                <Routes>
                    <Route path="/" element={<PageHome />} />
                    <Route path="about" element={<PageAbout />} />
                    <Route path="works" element={<PageWorks />} />
                    <Route path="contact" element={<PageContact />} />
                    <Route path="works/:id/:slug" element={<PageSingle />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default AppRouter;